@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
}

.ui.dropdown input {
  color: white;
}
.ui.selection.dropdown {
  min-width: 18em;
}
.ui.modal>.content{
  background-color: #1C1C1C;
}

.overlay {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;

  background-color: #3C3C3C;
  opacity:0.8;

  z-index:1001;

  display: none;
}

.show {
  display: block !important;
}


.ReactVirtualized__Grid__innerScrollContainer {
  width: auto !important;
  max-width: none !important;
}